import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import SearchHome from "../../../components/public/others/search/SearchHome";
import Buttons from "../../../components/public/buttons/ButtonHome";
import Banner from "../../../components/public/img/Banner";
import News from "../../../components/public/news/News";
import Footer from "../../../components/public/others/Footer";
import buttonsNavbar from "../../../components/jsons/jsonButtons";
import buttonsNavbarCM from "../../../components/jsons/jsonButtonsCM";
import { TipoPortal } from "../../../services/variables";
import AvisosHome from "../../../components/public/others/avisos_home";

export default function Home() {
  return (
    <>
      {TipoPortal === 3 ? <NavMenu /> : <NavMenuCM />}
      {TipoPortal === 3 ? <SearchHome /> : null}
      {TipoPortal === 4 ? <div className="spaceDivCM" /> : null}
      <div className={TipoPortal === 4 ? "GroupBottomCM" : ""}>
        <Buttons
          names={TipoPortal === 3 ? buttonsNavbar : buttonsNavbarCM}
          linkFilter={["Inicio"]}
        />
        <Banner />
        <News />
        <AvisosHome />
        <Footer />
      </div>
    </>
  );
}