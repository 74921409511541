import styles from "../../../../styles/module/transparency/sacop.module.css";

export function CardAvisos({ data }) {
  return (
    <div className={styles.card_aviso}>
      <a
        href={`/licitacoes-e-contratos/detalhes/${data.ID}#docs`}
        target="_self"
        rel="noopener noreferrer"
        title="Detalhes"
        aria-label="Detalhes"
        role="link"
        tabIndex={100}
      ></a>
      <ul>
        <li>
          <b>Número do Processo:</b> {data.NUMERO_PROCESSO}
        </li>
        <li>
          <b>Número do Instrumento:</b> {data.NUMERO_INSTRUMENTO}
        </li>
        <li>
          <b>Modalidade:</b> {data.MODALIDADE_NOME}
        </li>
        <li>
          <b>Ano de Exercício:</b> {data.EXERCICIO}
        </li>
      </ul>
      <h4>{data.OBJETO}</h4>

      <picture>
        <source srcSet="/icons/licitaçoes.svg" type="image/svg+xml" />
        <img
          className={styles.icon}
          src="/icons/licitaçoes.svg"
          alt="martelo licitacao icone"
        />
      </picture>
    </div>
  );
}
